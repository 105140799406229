:root {
  --primary-color: #f9b84c;
  --secondary-color: #234c73;
  --secondary-color-hover: #2e5981;
  --background-color: #000000;
}

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--primary-color);
}

footer.footer {
  background-color: #212529;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-brand {
  width: 100%;
}

.bg-background {
  background-color: var(--background-color);
}

.form-error {
  color: red;
}

.optional-input {
  font-size: 0.7rem;
}

th,
td {
  width: 33%;
}

span.pledge-count.count {
  font-weight: 800;
}

span.pledge-count {
  font-size: 1.4rem;
}

.row.pledge-count {
  background-color: #212529;
  padding: 10px 0;
  position: sticky;
}

.btn-primary {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-color: var(--secondary-color);
}

.btn-primary:hover {
  background-color: var(--secondary-color-hover);
  border-color: var(--secondary-color-hover);
  color: var(--primary-color);
}

.submit-btn {
  font-weight: 600;
}

.pledge-button {
  width: 100%;
  font-size: 1.4rem;
  /* text-transform: uppercase; */
}

.modal-content {
  background-color: #423e3e;
}

.mission1 {
  font-size: 1.4rem;
}

h2.contributors {
  font-weight: 600;
}

ol,
p.mission {
  font-size: 1.2rem;
}

.mission,
p {
  line-height: 2;
  color: white;
  letter-spacing: 2;
}

p.donation {
  color: rgb(155, 139, 118);
}

@media (min-width: 576px) {
  img.banner {
    width: 80%;
    height: auto;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  img.banner {
    width: 75%;
    height: auto;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  img.banner {
    width: 50%;
    height: auto;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* Extra extra large devices (large desktops, 1400px and up) */
@media (min-width: 1400px) {
  img.banner {
    width: 40%;
    height: auto;
  }
}
